import React from 'react'
import PromotionImgSrc from "../../static/images/sylphina_banner.png"
import { Link } from 'gatsby'

const Promotion = props => (
  <a href="https://sylphina.jp" target="_blank">
    <img src={PromotionImgSrc} alt="promotion image" />
  </a>
)

export default Promotion
