import React from 'react'
import styled from '@emotion/styled'
require('prismjs/themes/prism-okaidia.css')

const Body = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  h1,
  h2,
  h3 {
    text-transform: capitalize;
  }

  h1 {
    font-size: 2.5em;
    font-weight: 600;
    margin: 1.5em 0 1.5em 0;
    padding: 0.5em;
    color: #494949;
    background: #f8f8ff;
    border-left: solid 5px #00008b;
  }
  h2 {
    font-size: 2em;
    font-weight: 600;
    line-height: 1.25;
    margin: 1.5em 0 1.5em 0;
    padding: 0 0 0.1em 0;
    border-bottom: 4px solid #dcdcdc;
  }
  h3 {
    font-size: 1.15em;
    font-weight: 600;
    line-height: 1.25;
    margin: 1em 0 1em 0;
  }
  h4 {
    font-size: 1.1em;
    font-weight: 600;
    margin: 0 0 0.5em 0;
  }

  p {
    line-height: 1.6;
    margin: 0 0 2em 0;
  }

  a {
    transition: 0.2s;
    color: #6495ed;
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }

  del {
    text-decoration: line-through;
  }
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }

  ul,
  ol {
    margin: 0 0 2em 0;
  }

  ul {
    li {
      list-style: disc;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
      line-height: 1.5;
      padding-bottom: 0.5em;
      padding-inline-start: 1.5em;
      
    }
  }

  ol {
    li {
      list-style: decimal;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
      line-height: 1.5;
      padding-bottom: 0.5em;
      padding-inline-start: 1.5em;
    }
  }

  hr {
    border-style: solid;
    border-color: #dcdcdc;
    margin: 0 0 2em 0;
  }

  blockquote {
    background-color: #f5f5f5;
    font-size: 0.9em;
    font-style: italic;
    border-left: 4px solid ${props => props.theme.colors.secondary};
    padding: 0 0 0 0.5em;
    margin: 0 0.5em;
  }

  pre {
    font-size: 0.8em;
    margin: 0 0 2em 0;
    border-radius: 2px;
  }

  table {
    border-collapse: collapse;
    margin: 0 auto;
    margin-top: 0em;
    margin-bottom: 2em;
    padding: 0;
    width: 100%;
      box-shadow: 0 0 15px -6px #00000073;
  }

  table tr {
    background-color: #fff;
  }

  table tbody tr:hover{
    background-color: #f0f8ff;
  }

  table th,

  table td {
    font-size: 0.8em;
    padding: .35em 1em;
    border-bottom: 1px solid #eee;
  }

  table thead th {
    font-size: .85em;
    font-weight: bold;
    padding: 1em;
  }

  table thead tr{
    background-color: #0290b0;
    color:#fff;
  }
  table tbody th {
    text-align: left;
    font-size: .8em;
  }
`

const PageBody = props => {
  return (
    <Body
      dangerouslySetInnerHTML={{ __html: props.body.childMarkdownRemark.html }}
    />
  )
}

export default PageBody
